import axios from 'axios';

export interface loyaltyTypeDto {
  operation_id: number
  status: number
  need_verify: boolean
  user: User
  discount?: loyaltyDiscountDto
  cashback?: loyaltyCashbackDto
  signature: string
}

interface loyaltyDiscountDto {
  summ: number
  value: string
  loyalty_level: string
}

interface loyaltyCashbackDto {
  existed_summ: number
  allowed_summ: number
  value: string
  will_add: number
  loyalty_level: string
}

export interface PositionDto {
  name: string
  quantity: number
  price: number
}

export interface User {
  user_id: number // 1398946
  template: string // Золото
  first_name: string
  last_name: string
  pat_name: string
  phone: string // 79876543210
  email: string
  birthday: string
  gender: string
  barcode: string // 0000001
  discount: string // 10%
  bonus: number // 120
  loyalty_level: string // Уровень А
  operation_id: number
}

export default class AsgkApi {
  private readonly url: string;
  private readonly salePoint: string;

  constructor(salePoint: string) {
    this.url = 'https://onlinestore.asgk-group.ru/';
    this.salePoint = salePoint;
  }

  async cardDeliveryForm(): Promise<string> {
    return axios.get(this.url + 'carddeliveryform')
      .then(data => {
        return data.data.link ?? ''
      })
  }

  async searchUsers(phone: string): Promise<{ user: User[]; status: number }> {
    return axios.post(this.url + 'searchuser', {
      salepoint: this.salePoint,
      phone
    }).then(data => {
      return data.data
    })
  }

  async operation(operationData: {
    id?: string,
    phone?: string,
    barcode?: string,
    operation_number: string, // номер операции магазина
    positions: PositionDto[],
    summ: number,
    summ_without_discount: number,
    cashier: string
  }): Promise<loyaltyTypeDto> {
    return axios.post(this.url + 'operation', {
      salepoint: this.salePoint,
      ...operationData
    }).then(data => {
      return data.data
    })
  }

  async sendCode(user_id: number, operation_id: number): Promise<{
    status: number,
    user: object,
    send_by: string | 'SMS' | 'CALL(last 4)' | 'CARD(введите код подтв)'
  }> {
    return axios.post(this.url + 'sendcode', {
      salepoint: this.salePoint,
      user_id,
      operation_id
    }).then(data => {
      return data.data
    })
  }

  async verifyCode(user_id: number, operation_id: number, code: number, bonus_write_off: number): Promise<{
    status: number,
    user: User,
    message: string,
    write_off: number
  }> {
    return axios.post(this.url + 'verifycode', {
      salepoint: this.salePoint,
      user_id,
      operation_id,
      code,
      bonus_write_off
    }).then(data => {
      return data.data
    })
  }

  async submitOperation(user_id: number, operation_id: number, code: number, bonus_write_off: number): Promise<{
    status: number,
    message: string
  }> {
    return axios.post(this.url + 'submitoperation', {
      salepoint: this.salePoint,
      user_id,
      operation_id,
      code,
      bonus_write_off
    }).then(data => {
      return data.data
    })
  }
}