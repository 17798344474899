import React from "react";

interface RegLinkDto {
  registrationLink: string
}

export default function (props: RegLinkDto) {
  return (props.registrationLink)
    ? <div style={{
      margin: '30px 0'
    }}>
      <button type="button"
              className="t-submit"
              style={ { color: "#ffffff", backgroundColor: "#000000", marginBottom: 10 } }
              onClick={ () => {
                window.open(props.registrationLink, '_blank');
              } }
      >
        Зарегистрироваться в программе лояльности
      </button>
      <div className="t706__cartwin-top" style={ {
        fontSize: 14,
        fontWeight: 400,
        textAlign: 'center',
        color: '#000',
        fontFamily: 'Manrope'
      } }>Для того, чтобы зарегистрироваться в нашей программе лояльности,
        заполните форму регистрации, которая доступна по кнопке выше.
      </div>
    </div>
    : <div>Произошла ошибка при загрузке программы лояльности. Пожалуйста, отключите VPN, перепроверьте интернет-соединение и обновите страницу. При повторной ошибке свяжитесь с администрацией сайта.</div>
}
